import React from "react";

export interface IframeProps {
  slidoLink: string;
}

const MobileSlido = (props: IframeProps) => {
  const { slidoLink } = props;
  return (
    <iframe src={slidoLink} width="100%" height="100%" frameBorder="0"></iframe>
  );
};

export default MobileSlido;
