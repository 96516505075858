import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { animation_blur } from "../utils/animations"
import { mediaMax } from "../../../utils/breakpoints"
import NordurljosSVG from "../../Svg/NordurljosSVG"
import Fullscreen from "../../Svg/Fullscreen";
import QRCode from "../../../images/QRMarel.png";
import MobileSlido from "./mobileSlido"
import { FollowMeIframeRatio } from '../../FollowMeIframe'

interface VideoRoomProps {
  videoLink: string
  slidoLink?: string
  backgroundImage?: any
  lineSvgName?: string
}

const VideoRoom = ({
  videoLink,
  slidoLink,
  backgroundImage,
  lineSvgName,
}: VideoRoomProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        qrCode: file(relativePath: { eq: "QRMarel.png" }) {
          childImageSharp {
            fixed(width: 150, height: 150) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const videoRef = useRef(null);
  const slidoRef = useRef(null);
  const buttonRef = useRef(null);
  const QRref = useRef(null);
  const [qrActive, setQrActive] = useState(false)
  const [Active, setActive] = useState("notActive")
  const [Blurred, setBlurred] = useState(false)
  const [Lines, setLines] = useState(null)
  const [LiveBackground, setLiveBackground] = useState(
    <Img fluid={backgroundImage} style={{ height: "100%" }} />
  )

  useEffect(() => {
    if (lineSvgName != null && lineSvgName != undefined) {
      if (lineSvgName == "NordurljosSVG") {
        setLines(NordurljosSVG)
      }
    }
    const timeoutId = setTimeout(function () {
      QRref.current.classList.add('visible')
    }, 5000);

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  const handleClick = () => {
    setQrActive(!qrActive);
  }

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <StyledContainer>
      <SvgContainer>{Lines}</SvgContainer>
      <StyledBackgroundImage className={Blurred == true ? "blurred" : ""}>
        {LiveBackground}
      </StyledBackgroundImage>

      <StyledVideoContainer className={`${Active} ${isFullScreen ? 'fullscreen' : ''}`}>
        <StyledVideoWrapper ref={videoRef}>
          {/* className="video" */}
          <FollowMeIframeRatio ratio="56.25">
            <iframe
              src={videoLink}
              frameBorder="0"
            ></iframe>

          <EnlargeButton 
            ref={buttonRef} 
            id="EnlargeButton" 
            onClick={() => setIsFullScreen(!isFullScreen)}>
              <Fullscreen fill="#fff"></Fullscreen>
          </EnlargeButton>
          </FollowMeIframeRatio>

        </StyledVideoWrapper>

        <SlidoFrameContainer ref={slidoRef}>
          
          <iframe
            src={slidoLink}
            className="slidoFrame"
            height="100%"
            width="100%"
            frameBorder="0"
          ></iframe>

          <QrButton
            ref={QRref}
            className={qrActive ? 'display' : 'button'}
            onClick={handleClick} >
            <img src={QRCode} />
          </QrButton>
        </SlidoFrameContainer>

        <div className="mobileSlido__container">
          <MobileSlido slidoLink={slidoLink} />
        </div>
      </StyledVideoContainer>
    </StyledContainer>
  )
}

const QrButton = styled.a`
  background-color: white;
  width: max(100px, 6.944vw);
  height: max(100px, 6.944vw);
  position: absolute;
  right: -30px;
  z-index: -1;
  bottom: -20px;
  cursor: pointer;
  transition: 0.2s ease transform, 0.5s linear opacity;
  
  &.visible{
    opacity: 1;
  }
  img{
    height: 100%;
    width: 100%;
  }
  &:hover{
    transform: translateX(10px)
  }
  &.display{
    z-index:1;
    &:hover{
      transform: translateX(0)
    }
  }
`

const StyledVideoWrapper = styled.div`
  position: relative;
  width: 50%;
  
  &:hover{
    #EnlargeButton{
      opacity: 1;
    }
  }
`

const EnlargeButton = styled.a`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 21;
  width: max(50px, 3.47222vw);
  height: max(50px, 3.47222vw);
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: 0.2s ease;
  opacity:0;
  
  &.active{
    opacity: 0;
  }
  &:hover{
    opacity: 1;
  }
  @media ${mediaMax.tabletL} {
    display:none;
  }
`
const SvgContainer = styled.div`
  z-index: 20;
  position: absolute;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
  }
`

const SlidoFrameContainer = styled.div`
  margin-left: 30px;
  height: 50%;
  max-width: 400px;
  min-width: 380px;
  position: relative;
  &.active{
    height: 100%;
    margin-left: 0;
    max-width: 100%;
  }
  h2 {
    font-weight: 300;
    color: #fff;
    text-align: center;
  }
  .slidoFrame {
    top: 0;
  }
  @media ${mediaMax.tabletL} {
    display: none;
  }
`

const StyledBackgroundImage = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  transition: opacity 0s !important;

  img {
    transition: opacity 0s !important;
    opacity: 1 !important;
  }
`

const StyledVideoContainer = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  z-index: 21;
  .mobileSlido__container {
    display: none;
  }
  &.active {
    transform: translate3d(0, -100vh, 0);
    transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
  }

  &.notActive {
    transform: translate3d(0, 0, 0);
  }

  &.fullscreen ${StyledVideoWrapper} {
    flex: 1;
    background: #303030;
    height: 100%;
    display: flex;
    align-items: center;
  }
  
  &.fullscreen ${SlidoFrameContainer} {
    margin: 0;
    height: 100%;
    padding-top: 110px;
    background: #303030;
  }

  @media ${mediaMax.mobileL} {
    margin-top: 5px;
  }

  @media ${mediaMax.mobileL} {
    ${StyledVideoWrapper} {
      width: 100% !important;
    }
  }
  @media ${mediaMax.tabletL} {
    flex-direction: column;
    ${StyledVideoWrapper} {
      width: 90% !important;
    }

    .mobileSlido__container {
      display: block;
      width: 90%;
    }
  }
`

const StyledContainer = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
`

const QrCodeContainer = styled.div`
  position: relative;
  width: 200px;
  height: 265px;
  top: -285px;
  right: -11px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 21;

  > h2 {
    font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
    color: #000;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    font-size: 36px;
  }
  > img {
    height: 50px;
    width: 50px;
  }

  @media ${mediaMax.tabletL} {
    display: none;
  }

  @media (max-height: 800px) {
    display: none;
  }
`

export default VideoRoom
