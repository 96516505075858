import React from "react";

const NordurljosSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100vw"
      height="100vh"
      viewBox="0 0 1440 800"
      preserveAspectRatio="xMidYMid slice"
    >
      <defs>
        <path id="path-1" d="M0 0H1440V800H0z"></path>
        <linearGradient
          id="linearGradient-3"
          x1="100%"
          x2="0%"
          y1="45.334%"
          y2="54.666%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-4"
          x1="100%"
          x2="0%"
          y1="45.225%"
          y2="54.775%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-5"
          x1="100%"
          x2="0%"
          y1="49.999%"
          y2="50.001%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-6"
          x1="100%"
          x2="0%"
          y1="41.883%"
          y2="58.117%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-7"
          x1="100%"
          x2="0%"
          y1="48.358%"
          y2="51.642%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-8"
          x1="100%"
          x2="0%"
          y1="48.506%"
          y2="51.494%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-9"
          x1="100%"
          x2="0%"
          y1="42.673%"
          y2="57.327%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
        <linearGradient
          id="linearGradient-10"
          x1="100%"
          x2="0%"
          y1="48.456%"
          y2="51.544%"
        >
          <stop offset="0%" stopColor="#F3DC30"></stop>
          <stop offset="100%" stopColor="#CE5120"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            stroke="url(#linearGradient-3)"
            strokeWidth="2"
            d="M1058 361L1439.735 244"
          ></path>
          <path
            stroke="url(#linearGradient-4)"
            strokeWidth="2"
            d="M-0.686 357.458L379.396 240"
            transform="matrix(-1 0 0 1 378.71 0)"
          ></path>
          <path
            stroke="url(#linearGradient-5)"
            strokeWidth="2"
            d="M460.164 369L972.164 367"
            transform="matrix(-1 0 0 1 1433.164 0)"
          ></path>
          <path
            stroke="url(#linearGradient-5)"
            strokeWidth="2"
            d="M460.164 342L972.164 340"
            transform="matrix(-1 0 0 1 1433.164 0)"
          ></path>
          <path
            stroke="url(#linearGradient-6)"
            strokeWidth="2"
            d="M-0.686 323.864L381.201 170"
            transform="matrix(-1 0 0 1 380.516 0)"
          ></path>
          <path
            stroke="url(#linearGradient-7)"
            strokeWidth="2"
            d="M1014.87207 523.011719L1046.38281 523.011719 1440 600.053906"
          ></path>
          <path
            stroke="url(#linearGradient-8)"
            strokeWidth="2"
            d="M9.09494702e-13 520.579102L34.675293 520.579102 419.335449 593.053906"
            transform="matrix(-1 0 0 1 419.335 0)"
          ></path>
          <path
            stroke="url(#linearGradient-9)"
            strokeWidth="2"
            d="M1057 330L1439.735 183"
          ></path>
          <path
            stroke="url(#linearGradient-10)"
            strokeWidth="2"
            d="M136 0L403.376953 206 1050.94531 201.770508 1308.37695 0"
            mask="url(#mask-2)"
            transform="matrix(-1 0 0 1 1444.377 0)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default NordurljosSVG;
