import React, { useState } from "react";
import styled from "styled-components";
import Iframe from "./slidoIframe";
import slidoImage from "../../../images/slido-logo-vector.svg";
import Close from "../../../components/Svg/Close";

export interface SlidoProps {
  slidoLink: string;
  onClick?: (slidoLink: string) => string;
}

const MobileSlido = (props: SlidoProps) => {
  const [loadIframe, setLoadIframe] = useState(false);
  const { slidoLink } = props;

  return (
    <>
      <SlidoWrapper
        className={`${loadIframe ? "open" : ""}`}
        onClick={() => setLoadIframe(!loadIframe)}
      >
        <div className="slido-header">
          <img className="slido-image" src={slidoImage} />
          <Close className="slido-close" height={"18px"} width={"18px"} />
        </div>
        <Iframe slidoLink={slidoLink} />
      </SlidoWrapper>
    </>
  );
};

export default MobileSlido;

const SlidoWrapper = styled.div`
  display: block;
  height: 45px;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 10px;
  overflow: hidden;
  background: #fff;

  &.open {
    height: 50vh;
    transition: all 0.5s;
    .slido-close {
      transform: rotate(0);
    }
  }

  .slido-header {
    position: relative;
    position: relative;
    display: flex;
    height: 45px;
    width: 100%;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    background: #fff;
    z-index: 3;
  }

  .slido-close {
    position: absolute;
    right: 15px;
    transform: rotate(45deg);
    transition: transform 0.5s;
  }
`;
