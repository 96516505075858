import React, { useContext, useEffect } from 'react'

import Layout from '../components/Layout';
import { MyLocationEnum } from '../components/Layout/utils/enum'
import VideoRoom from '../components/ConferenceRoom/components/VideoRoom';
import SEO, { MetaOG } from "../components/Seo"
import { useStaticQuery, graphql } from 'gatsby';

import PeristenceContext from "../components/LayoutPersistent/context/PersistentContext";

interface locationConditionSettings {
  showHome: boolean,
  showGoLive: boolean,
  showClose: boolean,
  backTo?: string
}

interface Props {
  location: Location
}
export default ({ location }: Props) => {
  const seo_og: MetaOG = {
    title: "Bein útsending",
    description: "Bein útsending frá heilbrigðisþingi 2020",
  }
  const { updateVideoLink, videoLink } = useContext(PeristenceContext)
  const videoLinkVimeo = "https://player.vimeo.com/video/482033753"
  useEffect(()=>{
    updateVideoLink(videoLinkVimeo);
  }, [])

  const data = useStaticQuery(
    graphql`
      query {
        Background: file(relativePath: { eq: "Marel-bein-útsending-bakgrunnur.jpeg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout location={location} myLocation={MyLocationEnum.Live}>
      <SEO
        title="Bein útsending"
        description="Bein útsending frá árshátíð Marel"
        og={seo_og}
      />
      <VideoRoom 
        videoLink="https://player.vimeo.com/video/482033753"
        slidoLink="https://app.sli.do/event/j38vudfu" 
        backgroundImage={data.Background.childImageSharp.fluid} 
      />
    </Layout>
  )
}